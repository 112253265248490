<template>
  <div class="container_team">
    <overlay-loader :loading="isLoading" />
    <div class="team">
      <div class="team__head">
        <data-card
          v-for="(card, idx) in dataCards"
          :key="`card-${idx}`"
          class="team__card"
          :theme="card.theme"
          :card-id="card.cardId"
          :name="card.name"
          :value="card.value"
        >
          <account-multiple-icon />
        </data-card>
      </div>
      <filters
        :display="['company', 'campaign', 'status']"
        :status="selectedStatus"
      />
      <hcc-table
        @pageChanged="changePage"
        @sortData="dataSort"
        :emptyRowsTable="emptyRows"
        @search="searchUser"
        :filters="true"
        :remote="isRemote"
        :rows="rows"
        :columns="columns"
        :rowsPerPage="10"
        :pagination="pagination.total > 10"
        :actualPage="pagination.page"
        :totalPages="pagination.total"
      >
        <template slot="table-filter">
          <button-filter
            class="team__filter"
            ref="filter"
            :display="displayFilters"
            @change="onFiltersChanged"
          >
            <hcc-select
              custom-class="select"
              placeholder="Status"
              optionLabel="name"
              :options="allStatus"
              v-model="status"
              @select="selectStatus"
            />
          </button-filter>
        </template>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'status'">
            <div v-if="props.row.status === 'online'">
              <hcc-status-dot
                size="md"
                active
                class="status-dot"
                @click="enableClick ? openDisconectModal(props.row) : null"
                v-tooltip="enableClick ? $t('team.disconnect') : null"
              />
            </div>
            <div v-else>
              <hcc-status-dot
                size="md"
                :color="props.row.status"
                class="status-dot"
                @click="
                  enableToolTip(props.row.status)
                    ? openDisconectModal(props.row)
                    : null
                "
                v-tooltip="tootTip(props.row.status)"
              />
            </div>
          </span>
        </template>
      </hcc-table>
    </div>
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import executeQuery from '@/utils/gql-api';
import totalsUserStatus from '@/graphql/queries/users/userStatusAll.gql';
import logOutAgents from '@/graphql/mutations/userChangeStatus.gql';
import team from '@/graphql/queries/campaigns/team.gql';
import teamStatusChange from '@/graphql/suscriptions/teamStatusChange.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import Filters from '@/components/Filters.vue';

export default {
  components: {
    OverlayLoader,
    Filters,
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccStatusDot: () => import('@/components/shared/HccStatusDot/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    DataCard: () => import('@/components/DataCard.vue'),
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      status: null,
      totalStatusTeam: [],
      rows: [],
      isLoading: false,
      displayFilters: ['campaign'],
      filters: [],
      isRemote: true,
      sortColumn: 'email',
      sortOrder: 'asc',
      total: 0,
      searchText: null,
      pagination: {
        total: 0,
        page: 1,
      },
      selectedStatus: null,
      disabledStatus: false,
    };
  },
  computed: {
    ...mapState({
      language: state => state.shared.lang,
    }),
    ...mapGetters({
      isOwner: 'shared/isOwner',
      user: 'shared/userInfo',
      currentCampaign: 'setting/getCurrentCampaign',
    }),
    dataCards() {
      return [
        {
          theme: 'green',
          cardId: 'online',
          name: 'team.online',
          value: this.statusCard('online'),
        },
        {
          theme: 'gray',
          cardId: 'offline',
          name: 'team.offline',
          value: this.statusCard('offline'),
        },
        {
          theme: 'orange',
          cardId: 'stand-by',
          name: 'team.stand-by',
          value: this.statusCard('standBy'),
        },
        {
          theme: 'blue',
          cardId: 'admins',
          name: 'team.administrators',
          value: this.statusCard('admins'),
        },
      ];
    },
    allStatus() {
      return [{
        name: this.$t('team.all'),
        id: null,
      }, {
        name: this.$t('team.online-select'),
        id: 'online',
      }, {
        name: this.$t('team.offline-select'),
        id: 'offline',
      }, {
        name: this.$t('team.stand-by-select'),
        id: 'standBy',
      }];
    },
    columns() {
      return [{
        label: 'Status',
        field: 'status',
      }, {
        label: this.$t('common.name'),
        field: 'name',
      }, {
        label: this.$t('common.email'),
        field: 'email',
      }, {
        label: this.$t('team.progress'),
        field: 'progress',
        sortable: false,
      }, {
        label: this.$t('team.assigned'),
        field: 'assigned',
        sortable: false,
      }, {
        label: this.$t('team.finished'),
        field: 'finished',
        sortable: false,
      }];
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : 0;
    },
    emptyRows() {
      return this.total === 0;
    },
    enableClick() {
      return ['super', 'owner', 'admin'].includes(this.user.role);
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if ((oldValue === null
        || newValue.companyId !== oldValue.companyId
        || newValue.campaignId !== oldValue.campaignId)
        && newValue.campaignId != null) {
        this.pagination.page = 1;
        this.fetchTeamData(newValue);
        this.disabledStatus = false;
      } else if (newValue.companyId !== oldValue.companyId && !newValue.campaignId) {
        this.disabledStatus = true;
        this.rows = [];
      }
    },
    status() {
      this.pagination.page = 1;
      this.fetchTeamData(this.filters);
    },
    currentCampaign(newVal) {
      if (newVal.id === this.filters.campaignId) {
        this.pagination.page = 1;
        this.fetchTeamData(this.filters);
      }
    },
    language() {
      if (this.status) {
        this.selectedStatus = this.allStatus.find(status => status.id === this.status.id)?.name;
        this.status = this.allStatus.find(status => status.id === this.status.id);
      }
    },
  },
  mounted() {
    if (this.isOwner) {
      this.displayFilters.push('company');
    }
    [this.status] = this.allStatus;
    this.searchText = null;
  },
  apollo: {
    $subscribe: {
      teamStatus: {
        query: teamStatusChange,
        variables() {
          return { companyId: this.currentCompanyId };
        },
        result({ data }) {
          const { teamStatus: agent } = data;
          const index = this.rows.findIndex(({ id }) => id === agent.id);
          if (index !== -1) {
            this.udateByFilters(agent, index);
            this.fetchTotalUsersByStatus();
          }
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
    },
  },
  methods: {
    statusCard(name) {
      const total = this.totalStatusTeam.find(({ _id }) => _id === name);
      return total && total.count ? total.count : 0;
    },
    async fetchTotalUsersByStatus() {
      this.totalStatusTeam = await executeQuery(
        'totalsUserStatus', totalsUserStatus,
        {
          company: this.currentCompanyId,
          campaign: this.filters.campaignId,
        },
        false,
      );
    },
    async searchUser({ value, key }) {
      if (key) {
        this.pagination.page = 1;
        this.searchText = value;
        this.fetchTeamData(this.filters);
      } else if (value === '' && key) {
        this.fetchTeamData(this.filters);
      }
    },
    async fetchTeamData(filters) {
      this.isLoading = true;
      this.fetchTotalUsersByStatus();

      const response = await executeQuery(
        'team', team, {
          companyId: this.currentCompanyId,
          campaignId: filters.campaignId,
          status: this.status.id,
          page: this.pagination.page,
          perPage: 10,
          text: this.searchText,
          sortBy: this.sortColumn,
          sortOrder: this.sortValue,
        }, false,
      );
      this.pagination.total = response.total;
      this.rows = response.data;
      this.isLoading = false;
    },
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    async openDisconectModal(props) {
      this.$modal.show('confirmation', {
        title: this.$t('team.disconnect-confirmation', { name: props.name }),
        description: this.$t('team.description'),
        confirm: () => this.discconectAgentAction(
          { id: props.id, status: 'OFFLINE' },
        ),
      });
    },
    async discconectAgentAction(variables) {
      await this.$apollo.mutate({ mutation: logOutAgents, variables });
      this.$toasted.global.success(this.$t('team.response'));
    },
    tootTip(status) {
      return this.enableClick && status !== 'offline' ? this.$t('team.disconnect') : 'Disconected';
    },
    enableToolTip(status) {
      return this.enableClick && status !== 'offline';
    },
    changePage(page) {
      this.pagination.page = page;
      this.fetchTeamData(this.filters);
    },
    dataSort(params) {
      this.sortColumn = params[0].field;
      this.sortValue = params[0].type === 'asc' ? 1 : -1;
      this.fetchTeamData(this.filters);
    },
    udateByFilters(params, index) {
      let statusByCampaign = params.conversation;
      if (this.filters.campaignId) {
        statusByCampaign = params.conversation.filter(({ id }) => id === this.filters.campaignId);
      }

      const total = statusByCampaign.reduce((acc, { finished, progress, assigned }) => {
        acc.finished += finished;
        acc.progress += progress;
        acc.assigned += assigned;
        return acc;
      }, { finished: 0, progress: 0, assigned: 0 });

      this.$set(this.rows, index, { ...params, ...total });
    },
    selectStatus(status) {
      this.selectedStatus = status.name;
    },
  },
};
</script>

<style scoped lang='scss'>
@import "~styles/views/_team.scss";
</style>
